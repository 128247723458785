import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/ansible-sudoers",
  "title": "Ansible Sudoers Module",
  "started": "2020-08-27T00:00:00.000Z",
  "published": "2020-08-29T00:00:00.000Z",
  "backgroundImage": "python",
  "tags": ["Ansible", "Sudoers", "Python"],
  "layoutClass": "ansible-sudoers",
  "thumbnail": "blog/2020-08-27-ansible-sudoers/ansible.png"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Ansible`}</h2>
    <Image className="image-right" alt="ansible" noLightbox path="blog/2020-08-27-ansible-sudoers/ansible.png" mdxType="Image" />
    <p><a parentName="p" {...{
        "href": "https://www.ansible.com/"
      }}>{`Ansible`}</a>{` is a configuration management tool to facilitate managing "infrastructure as code".
I use it extensively to manage up my `}<a parentName="p" {...{
        "href": "/blog/home-server"
      }}>{`home server`}</a>{` and other computer-based infrastructure.`}</p>
    <p>{`For example, I can take an empty Ubuntu machine, add an entry to my Ansible inventory with the appropriate roles, then
run the Ansible playbook against it to set it up as described in the Ansible code.`}</p>
    <p>{`It certainly beats logging in to the machine, installing packages, and editing config files by hand!`}</p>
    <h2>{`Sudoers`}</h2>
    <p>{`The Sudoers file describes what is permissible to run with sudo for a particular user or group.
To run a command as the super user, a user may run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo my-command`}</code>{` (sudo being short for "super user do").`}</p>
    <p>{`For example, this line allows any member of the sudo group to execute any command (requiring the user's password to do
so):`}</p>
    <span className="prism-title">sudoers</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`%sudo	ALL=(ALL:ALL) ALL`}</code></pre></div>
    <p>{`To simplify use, the requirement of the user's password can be dropped (essential for unattended use) for an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alice`}</code>{`
user with this line:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`alice ALL=(ALL:ALL) NOPASSWD:ALL`}</code></pre></div>
    <p>{`Sudo use with specific commands may also be granted - this is the case I wrote the Sudoers Ansible module for.`}</p>
    <p>{`On Ubuntu systems (at least) instead of writing all configuration to a single Sudoers file, any file in the
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/sudoers.d/`}</code>{` directory is read for configuration.
This is very helpful as it means that syntax errors in one of these files does not break sudo!`}</p>
    <p>{`Generally when I want to allow a user to sudo a command, I wrap the functionality into it's own script and allow the
user sudo access for that specific script alone.
If a user needs to be able to restart a service, instead of allowing the user to sudo
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/bin/systemctl restart myservice`}</code>{`, I'll write that to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/usr/local/bin/restart-myservice`}</code>{` and allow the user to sudo
that file.
This way, I don't need to worry about what options (and what order) may be used when writing the Sudoers rule.`}</p>
    <p>{`I use `}<a parentName="p" {...{
        "href": "https://sensu.io/"
      }}>{`Sensu`}</a>{` to orchestrate checks and metric gathering across my infrastructure, and have
implemented several checks to check for available updates for installed packages.
For example, checking for available `}<a parentName="p" {...{
        "href": "https://nextcloud.com/"
      }}>{`Nextcloud`}</a>{` updates is done by executing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`occ update:check`}</code>{`
and processing the output.
The sensu user should not have access to the Nextcloud files and executables, and I wouldn't want to run the Nextcloud
executables as root as that'd allow a privilege escalation for the nextcloud user.`}</p>
    <p>{`The check script (written to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/usr/local/bin/check-nextcloud-version.sh`}</code>{`) is as follows:`}</p>
    <span className="prism-title">check-nextcloud-version.sh</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`output`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`sudo`}</span>{` -u www-data php /var/www/html/nextcloud/occ update:check`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$output`}</span>{`"`}</span>{`

`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$output`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` -qE `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'updates? available'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$?`}</span>{` -eq `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exit`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span>{`

`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exit`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p>{`The script uses sudo to run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`occ update:check`}</code>{` as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`www-data`}</code>{` user.
It then prints the output (mostly for debug purposes) and checks to see whether "update available" or
"updates available" exists in the output of that command.
If updates are available, the check script returns 1 which indicates a warning level event to Sensu.
If the script did not print the output of the command, the update check command could be completely encapsulated within
this script.`}</p>
    <p>{`To allow this script to be executed by the monitoring group, the following should be written to a file in the
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/sudoers.d/`}</code>{` directory.`}</p>
    <span className="prism-title">monitoring-check-nextcloud-version</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`%monitoring ALL=NOPASSWD: /usr/local/bin/check-nextcloud-version.sh`}</code></pre></div>
    <h2>{`The Sudoers Module`}</h2>
    <p>{`While writing Ansible plays for my personal infrastructure, there were several cases where I wanted to manage sudo
access via the Sudoers feature my Ubuntu machines.
The most common use case for needing sudo access is to allow a monitoring agent to run some privileged command to check
or measure something that required elevated privileges to read.`}</p>
    <p>{`The main reason I wrote this `}<a parentName="p" {...{
        "href": "https://github.com/JonEllis/ansible-sudoers"
      }}>{`Sudoers Ansible module`}</a>{` was due to the number
of times I needed to look up the format for the Sudoers file each time I needed to use it (or at least copy it from a
previous use).`}</p>
    <p>{`Before I wrote the Sudoers module, I would use Ansible's copy module to write the file:`}</p>
    <span className="prism-title">YAML</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` allow monitoring to sudo the check
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`copy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`dest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/etc/sudoers.d/monitoring-check-nextcloud-version"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"%monitoring ALL=NOPASSWD:/usr/local/bin/check-nextcloud-version.sh\\n"`}</span></code></pre></div>
    <p>{`The new line character at the end is critical - it does not work without it.`}</p>
    <p>{`Using the module, the same functionality is achievable with this Ansbile:`}</p>
    <span className="prism-title">YAML</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` allow monitoring to sudo the check
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`sudoers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` monitoring`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`check`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`nextcloud`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`version            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The name of the file in the sudoers.d directory`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`group`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` monitoring                                   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# the user or group may be specified for this rule`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /usr/local/bin/check`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`nextcloud`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`version.sh  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# a command or list of commands that are being granted by this rule`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`nopassword`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`                                    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# whether a password is required when using sudo for this rule`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` present                                      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# whether this rule is to be kept or removed`}</span></code></pre></div>
    <p>{`My common defaults are set so you wouldn't normally set more than the name, group (or user), and command.`}</p>
    <p>{`The Sudoers module supports several of the use cases that I've used for the Sudoers file - mostly allowing access to
commands to specific users and groups.
It does not currently support the aliasing features as I've actually never used them.
I'm reasonably happy it'd be straight forward to add to this module though.`}</p>
    <p>{`The module supports check mode and returns whether the invocation of the module caused a change or not.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/JonEllis/ansible-sudoers"
      }}>{`See the repository readme for further usage examples`}</a>{`.`}</p>
    <h2>{`Ansible Galaxy`}</h2>
    <p>{`At iWeb, We have a very similar use-case when managing sudoers files, so I decided the best way to use it in both places
was to `}<a parentName="p" {...{
        "href": "https://galaxy.ansible.com/jonellis/sudoers"
      }}>{`publish it as an Ansible collection in Ansible Galaxy`}</a>{`.`}</p>
    <p>{`This turned out to be a reasonably straight forward thing to do, however I was initially confused as to how publish a
collection that only contained modules.
When this is written into an Ansible structure, it would be added to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`library`}</code>{` directory.
However for a collection, it should be placed in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`plugins/modules`}</code>{` directory.`}</p>
    <p>{`Secondly, I found the process to update the version frustrating as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`galaxy.yml`}</code>{` config file needs to be updated with
the tagged version of the code.
I had to go back and create a new version with the correct version in the config file and re-update it.
I would have liked the version to be populated or templated from the git tag if possible.`}</p>
    <p>{`The collection must be built and uploaded to Ansible galaxy, I'm sure I could have written a CI job to do this, but it
didn't seem worth it for the low frequency that I update the module.`}</p>
    <h2>{`Recognition`}</h2>
    <p>{`This repository earned me my first two stars on GitHub, and apparently is being stored in their
`}<a parentName="p" {...{
        "href": "https://github.blog/2020-07-16-github-archive-program-the-journey-of-the-worlds-open-source-code-to-the-arctic/"
      }}>{`Arctic Archive Program`}</a>{`.`}</p>
    <p>{`Hopefully this module will be useful for others too.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      